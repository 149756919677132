import React from 'react';
import { Modal } from 'antd';
import t from '@/utilities/translate';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { XAxisVerticalTick } from './XAxisVerticalTick';

const ChartModal = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		data,
		dataKeys,
	} = props;

	const formatYTick = (value) => {
		if (props.valuesType === 'percent') {
			return `${value} %`;
		} else {
			return value;
		}
	};

	const renderTooltipContent = (props) => {
		const { payload, label } = props;
		const isPercentValue = props.valuesType === 'percent';

		return (
			<div className={'recharts-tooltip'}>
				<b>{label}</b>
				<div>
					Value: {payload[0]?.payload.value} (<b>{payload[0]?.payload.value}%</b>)
				</div>
			</div>
		)
	};

	const dataSource = dataKeys.map((key, i) => ({
		name: t(`sales-efficiency/list/${key}`),
		value: data[key],
	}));

	return (
		<Modal {...modalProps} width={1200} title={data.user} footer={null}>
			<div>
				<div style={{ width: '100%', overflow: 'auto hidden' }}>
					<div style={{ minWidth: data.length * 45, width: '100%' }}>
						<ResponsiveContainer width={'100%'} height={500}>
							<BarChart
								data={dataSource}
								barSize={60}
								margin={{ bottom: 150, left: 20, top: 20, right: 20 }}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis
									dataKey="name"
									type={'category'}
									interval={0}
									tick={<XAxisVerticalTick width={150} />}
								/>
								<YAxis tickFormatter={formatYTick} />
								<Tooltip animationDuration={0} content={renderTooltipContent} />
								<Bar dataKey={'value'} fill={'#245F19'} />
							</BarChart>
						</ResponsiveContainer>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ChartModal;
